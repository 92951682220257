
<template>
  <div class="updatainvoice">
    <van-field name="radio" label="类型">
      <template #input>
        <van-radio-group v-model="checked" direction="horizontal">
          <van-radio name="C">个人</van-radio>
          <van-radio name="B">单位</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <div v-if="checked == 'C'">
      <van-form ref="upform">
        <van-cell-group inset>
          <van-field
            v-model="updataList.buyername"
            name="购方名称"
            label="购方名称"
            placeholder="请输入名称"
            :rules="[{ required: true, message: '请输入购方名称' }]"
          />
          <van-field
            v-model="updataList.buyertaxpayernum"
            name="识别码"
            label="识别码"
            placeholder="请输入个人输入身份证号"
          />
          <van-field
            v-model="updataList.buyertel"
            name="手机号"
            label="手机号"
            placeholder="请输入您的联系电话"
            :rules="[
              { required: true, message: '请输入您的联系电话' },
              {
                pattern:
                  /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
                message: '请输入正确的电话号码'
              }
            ]"
          />
          <van-field
            v-model="updataList.buyeremail"
            name="邮箱"
            label="邮箱"
            placeholder="邮箱地址，可用于接收电子发票"
            :rules="[
              { required: false, message: '请输入您的邮箱地址' },
              {
                pattern: /^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$/,
                message: '请输入正确的邮箱地址格式'
              }
            ]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" @click="submitBill">
            确认
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button round block color="#428bca" @click="backBilldetail">
            返回
          </van-button>
        </div>
      </van-form>
    </div>
    <div v-if="checked == 'B'">
      <van-form ref="upComform">
        <van-cell-group inset>
          <van-field
            v-model="updataList.buyername"
            name="购方名称"
            label="购方名称"
            placeholder="请输入名称"
            :rules="[{ required: true, message: '请输入购方名称' }]"
          />
          <van-field
            v-model="updataList.buyertaxpayernum"
            name="识别码"
            label="识别码"
            placeholder="请输入纳税人识别码"
            :rules="[{ required: true, message: '请输入识别码' }]"
          />
          <van-field
            v-model="updataList.buyertel"
            name="电话"
            label="电话"
            placeholder="请输入联系电话"
            :rules="[
              { required: true, message: '请输入联系电话' }
            ]"
          />
          <van-field
            v-model="updataList.buyeremail"
            name="邮箱"
            label="邮箱"
            placeholder="邮箱地址，可用于接收电子发票"
            :rules="[
              { required: false, message: '请输入邮箱地址' },
              {
                pattern: /^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$/,
                message: '请输入正确的邮箱地址格式'
              }
            ]"
          />
          <van-field
            v-model="updataList.buyeraddress"
            name="地址"
            label="地址"
            placeholder="请输入地址"
            :rules="[{ required: true, message: '请输入公司地址' }]"
          />
          <van-field
            v-model="updataList.buyerbankname"
            name="开户银行"
            label="开户银行"
            placeholder="请输入开户银行"
            :rules="[{ required: true, message: '请输入开户银行' }]"
          />
          <van-field
            v-model="updataList.buyerbankaccount"
            name="账号"
            label="账号"
            placeholder="请输入银行账号"
            :rules="[{ required: true, message: '请输入银行账号' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" @click="submitComBill">
            确认
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button round block color="#428bca" @click="backBilldetail">
            返回
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: 'C',
      updataList: {},
      mobile: ''
    }
  },
  created() {
    this.updataList = this.$route.query.updataObj
    this.checked = this.$route.query.active
    this.mobile = localStorage.getItem('mobile')
  },
  methods: {
    submitBill() {
      this.$refs.upform
        .validate()
        .then(() => {
          this.$axios
            .post(`head?mobile=${this.mobile}`, this.updataList)
            .then((res) => {
              if (res.data.retcode == '0000') {
                this.$dialog
                  .alert({
                    message: '发票抬头修改成功'
                  })
                  .then(() => {
                    this.$router.push('/billhead')
                  })
              } else {
                this.$dialog
                  .alert({
                    message: res.data.retmessage
                  })
                  .then(() => {
                    // on close
                  })
              }
            })
        })
        .catch(() => {
          this.$dialog
            .alert({
              message: '请填写正确的内容'
            })
            .then(() => {
              // on close
            })
        })
    },
    submitComBill() {
      this.$refs.upComform
        .validate()
        .then(() => {
          this.$axios
            .post(`head?mobile=${this.mobile}`, this.updataList)
            .then((res) => {
              if (res.data.retcode == '0000') {
                this.$dialog
                  .alert({
                    message: '发票抬头修改成功'
                  })
                  .then(() => {
                    this.$router.push('/billhead')
                  })
              } else {
                this.$dialog
                  .alert({
                    message: res.data.retmessage
                  })
                  .then(() => {
                    // on close
                  })
              }
            })
        })
        .catch(() => {
          this.$dialog
            .alert({
              message: '请填写正确的内容'
            })
            .then(() => {
              // on close
            })
        })
    },
    backBilldetail() {
      this.$router.push('/billhead')
    }
  }
}
</script>
<style scoped>
.updatainvoice {
  padding: 15px;
}
.updatainvoice .van-field__body {
  border: none;
}
.updatainvoice .van-button--round {
  border-radius: 5px;
}
</style>