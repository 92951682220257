var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updatainvoice"},[_c('van-field',{attrs:{"name":"radio","label":"类型"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('van-radio',{attrs:{"name":"C"}},[_vm._v("个人")]),_c('van-radio',{attrs:{"name":"B"}},[_vm._v("单位")])],1)]},proxy:true}])}),(_vm.checked == 'C')?_c('div',[_c('van-form',{ref:"upform"},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"name":"购方名称","label":"购方名称","placeholder":"请输入名称","rules":[{ required: true, message: '请输入购方名称' }]},model:{value:(_vm.updataList.buyername),callback:function ($$v) {_vm.$set(_vm.updataList, "buyername", $$v)},expression:"updataList.buyername"}}),_c('van-field',{attrs:{"name":"识别码","label":"识别码","placeholder":"请输入个人输入身份证号"},model:{value:(_vm.updataList.buyertaxpayernum),callback:function ($$v) {_vm.$set(_vm.updataList, "buyertaxpayernum", $$v)},expression:"updataList.buyertaxpayernum"}}),_c('van-field',{attrs:{"name":"手机号","label":"手机号","placeholder":"请输入您的联系电话","rules":[
            { required: true, message: '请输入您的联系电话' },
            {
              pattern:
                /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
              message: '请输入正确的电话号码'
            }
          ]},model:{value:(_vm.updataList.buyertel),callback:function ($$v) {_vm.$set(_vm.updataList, "buyertel", $$v)},expression:"updataList.buyertel"}}),_c('van-field',{attrs:{"name":"邮箱","label":"邮箱","placeholder":"邮箱地址，可用于接收电子发票","rules":[
            { required: false, message: '请输入您的邮箱地址' },
            {
              pattern: /^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$/,
              message: '请输入正确的邮箱地址格式'
            }
          ]},model:{value:(_vm.updataList.buyeremail),callback:function ($$v) {_vm.$set(_vm.updataList, "buyeremail", $$v)},expression:"updataList.buyeremail"}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"primary"},on:{"click":_vm.submitBill}},[_vm._v(" 确认 ")])],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","color":"#428bca"},on:{"click":_vm.backBilldetail}},[_vm._v(" 返回 ")])],1)],1)],1):_vm._e(),(_vm.checked == 'B')?_c('div',[_c('van-form',{ref:"upComform"},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"name":"购方名称","label":"购方名称","placeholder":"请输入名称","rules":[{ required: true, message: '请输入购方名称' }]},model:{value:(_vm.updataList.buyername),callback:function ($$v) {_vm.$set(_vm.updataList, "buyername", $$v)},expression:"updataList.buyername"}}),_c('van-field',{attrs:{"name":"识别码","label":"识别码","placeholder":"请输入纳税人识别码","rules":[{ required: true, message: '请输入识别码' }]},model:{value:(_vm.updataList.buyertaxpayernum),callback:function ($$v) {_vm.$set(_vm.updataList, "buyertaxpayernum", $$v)},expression:"updataList.buyertaxpayernum"}}),_c('van-field',{attrs:{"name":"电话","label":"电话","placeholder":"请输入联系电话","rules":[
            { required: true, message: '请输入联系电话' }
          ]},model:{value:(_vm.updataList.buyertel),callback:function ($$v) {_vm.$set(_vm.updataList, "buyertel", $$v)},expression:"updataList.buyertel"}}),_c('van-field',{attrs:{"name":"邮箱","label":"邮箱","placeholder":"邮箱地址，可用于接收电子发票","rules":[
            { required: false, message: '请输入邮箱地址' },
            {
              pattern: /^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$/,
              message: '请输入正确的邮箱地址格式'
            }
          ]},model:{value:(_vm.updataList.buyeremail),callback:function ($$v) {_vm.$set(_vm.updataList, "buyeremail", $$v)},expression:"updataList.buyeremail"}}),_c('van-field',{attrs:{"name":"地址","label":"地址","placeholder":"请输入地址","rules":[{ required: true, message: '请输入公司地址' }]},model:{value:(_vm.updataList.buyeraddress),callback:function ($$v) {_vm.$set(_vm.updataList, "buyeraddress", $$v)},expression:"updataList.buyeraddress"}}),_c('van-field',{attrs:{"name":"开户银行","label":"开户银行","placeholder":"请输入开户银行","rules":[{ required: true, message: '请输入开户银行' }]},model:{value:(_vm.updataList.buyerbankname),callback:function ($$v) {_vm.$set(_vm.updataList, "buyerbankname", $$v)},expression:"updataList.buyerbankname"}}),_c('van-field',{attrs:{"name":"账号","label":"账号","placeholder":"请输入银行账号","rules":[{ required: true, message: '请输入银行账号' }]},model:{value:(_vm.updataList.buyerbankaccount),callback:function ($$v) {_vm.$set(_vm.updataList, "buyerbankaccount", $$v)},expression:"updataList.buyerbankaccount"}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"primary"},on:{"click":_vm.submitComBill}},[_vm._v(" 确认 ")])],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","color":"#428bca"},on:{"click":_vm.backBilldetail}},[_vm._v(" 返回 ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }